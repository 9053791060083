import React from "react";
import Swal from 'sweetalert2';
import { 
    ButtonTittle,
    Content,
    SubTitle,
    Title
} from "../../../../components/content";

const Parallax = () => {

    const showAlert = (e) => {

        e.preventDefault();

        Swal.fire({
            title: 'Confirmação',
            text: 'Você será redirecionado para o WhatsApp, deseja continuar?',
            icon: 'success',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            customClass: {
                confirmButton: 'btn-parallax-confirm',
                cancelButton: 'btn-parallax-cancel',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                window.open('https://api.whatsapp.com/send?1=pt_BR&phone=5541995118654', '_blank');
            }
        });
    }

    return (
        <section id="parallax">
            <div className="container-fluid">
                <Content height={300}>
                    <Title fontWeight={"bold"}>
                        Solicite um orçamento!
                    </Title>
                    <SubTitle color={"#FFF"}>
                        Solicite seu orçamento agora mesmo que nossos representantes entrarão em contato.
                    </SubTitle>
                    <ButtonTittle style={{ marginTop: '20px' }}>
                        <a href="#void" onClick={showAlert}>CLIQUE AQUI!</a>
                    </ButtonTittle>
                </Content>
            </div>
        </section>
    )

}

export default Parallax;