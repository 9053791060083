import React from "react";

const Map = () => {

    return (
        <section id="map">
            <div className="map-content">
                <div className="map-title">
                    <h3>Onde Estamos?</h3>
                </div>
                <div id="map-container" className="container-fluid">
                    <iframe title="address" id="iframe-address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.497875885092!2d-46.68408518502159!3d-23.586470884670387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce2fff7a70b07%3A0xb1452640c5997fba!2sR.%20Nicolau%20Latchoc%2C%2055%20-%20Campo%20Comprido%2C%20Curitiba%20-%20PR%2C%2081220-360!5e0!3m2!1spt-BR!2sbr!4v1580487504932!5m2!1spt-BR!2sbr" />
                </div>
            </div>
        </section>
    )

}

export default Map;