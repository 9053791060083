import React from "react";
import { Content, Title, SubText } from "../../../../components/content";
import Logo from "../../../../assets/img/deskflow/logo.png"
import Smart from "../../../../assets/img/deskflow/smart.png"
import NonAdditionalCarousel from "./NonAdditional";
import AdditionalsCarousel from "./Additionals";
import StackCarousel from "./stack";

const Deskflow = () => {

    return (
        <div>
            <section id="deskflow">
                <div className="container">
                    <div className="row">
                        <Content>
                            <img id="deskflow-logo" src={Logo} alt="Logo do Deskflow" />
                            <img id="deskflow-sublogo" src={Smart} alt="Sub logo do deskflow" />
                            <SubText>
                                Com foco em produtividade, layout pensado em dispositivos touchscreen, a Z1Tec apresenta ao mercado a plataforma offline para gestão do seu negócio. <br />
                                Cansado de ficar sem sistema quando a internet cai? <br />
                                Nós temos a solução. <br />
                                Confira abaixo alguns recursos da nossa aplicação.
                            </SubText>
                        </Content>
                    </div>
                </div>
            </section>

            <section id="non-additional-modules" class="deskflow-carousel">
                <Content>
                    <Title color={"#000"}>
                        Conheça os módulos da nossa aplicação!
                    </Title>
                    <SubText>
                        Os módulos principais da nossa solução são:
                    </SubText>
                </Content>
                <div className="container carousel">
                    <NonAdditionalCarousel />
                </div>
            </section>

            <section id="additional-modules" class="deskflow-carousel">
                <Content>
                    <Title color={"#000"}>
                        Precisando de mais controle?
                    </Title>
                    <SubText>
                        Conheça os módulos adicionais da nossa aplicação:
                    </SubText>
                </Content>
                <div className="container carousel">
                    <AdditionalsCarousel />
                </div>
            </section>

            <section id="stack">
                <Content>
                    <Title color={"#000"}>
                        Tecnologias utilizadas
                    </Title>
                    <SubText color={"#000"}>
                        Conheça as tecnologias que utilizamos.
                    </SubText>
                </Content>
                <div className="container carousel-stack">
                    <StackCarousel/>
                </div>
            </section>
        </div>
    )

}

export default Deskflow