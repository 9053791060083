import React, { useState } from "react";
import ErpImage from "../../../../assets/img/deskflow/dashboard.png";
import SalesImage from "../../../../assets/img/deskflow/vendas.png";
import FinantialImage from "../../../../assets/img/deskflow/financeiro.png";
import PurchaseImage from "../../../../assets/img/deskflow/compras.png";
import SoImage from "../../../../assets/img/deskflow/os.png";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


const Hero = () => {

    const [open, setOpen] = useState(false);

    const images = [
        { src: ErpImage, alt: 'Dashboard' },
        { src: SalesImage, alt: 'Módulo de Vendas' },
        { src: FinantialImage, alt: 'Módulo Financeiro' },
        { src: PurchaseImage, alt: 'Módulo de Compras' },
        { src: SoImage, alt: 'Módulo de Ordem de Serviço' },
    ];

    return (
        <section id="hero">
            <div className="container">
                <div className="row">
                    <div className="col m6 s12 erp erp-left">
                        <div className="erp-left-title">
                            <h4>Conheça o Desk
                                <b>Flow</b>
                            </h4>
                        </div>
                        <span>
                            Com foco no no gerenciamento de pedidos, estoque e controle financeiro, o DeskFlow é uma solução que trabalha 100% offline visando a maior produtividade.
                            Em constante melhoria, contamos também com integração em tempo real com os pedidos do seu site!
                            <a href="/deskflow"> Clique aqui e saiba mais!</a>
                        </span>
                        <ul>
                            <li>Cadastro de Clientes;</li>
                            <li>Cadastro de Produtos;</li>
                            <li>Cadastro de Fornecedores;</li>
                            <li>Controle de Estoque;</li>
                            <li>Módulo Financeiro;</li>
                            <li>Módulo de  Compras;</li>
                            <li>Módulo de Ordem de Serviço;</li>
                            <li>Módulo de Vendas;</li>
                            <li>Módulo de Comandas;</li>
                            <li>Módulo de BI;</li>
                            <li>Módulo de Emissão de Notas Fiscais (Modelos 55 e 65) para todos os estados.</li>
                            <li><b>Integração com aplicativo exclusivo Android;</b></li>
                            <li><b>100% offline (Exceto para emissão de notas).</b></li>
                        </ul>
                    </div>
                    <div className="col m6 s12 erp erp-right">
                        <div>
                            <img
                                className="erp-image responsive-img"
                                src={ErpImage}
                                onClick={() => setOpen(true)}
                                alt="Imagem do Deskflow" />
                            <Lightbox
                                open={open}
                                close={() => setOpen(false)}
                                slides={images}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Hero;