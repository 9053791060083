export const initialFormData = {
    name: '',
    email: '',
    phone: '',
    subject: ''
};

export const toasterSettings = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}