import axios from "axios";
const BASE_URL = 'https://api.z1tec.com.br/v1';

const Api = {

  get: async (url) => {
    try {

      const response = await axios.get(`${BASE_URL}/${url}`);
      return response.data;

    } catch (error) {
      throw error;
    }
  },

  post: async (url, data) => {

    try {

      const response = await axios.post(`${BASE_URL}/${url}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  }
};

export default Api;