import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/header';
import Footer from './components/footer';
import Main from './pages/main';
import Deskflow from './pages/deskflow';

function App() {
  return (
    <div className="App">
      <div>
        <Header />
        <Router>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/deskflow" element={<Deskflow />} />
          </Routes>
        </Router>
        <Footer />
      </div>
    </div>
  );
}

export default App;
