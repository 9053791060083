import styled from "styled-components";

export const Content = styled.div`
    width: 100%;
    height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.padding ? `${props.padding}px` : '0px')};
`;

export const Title = styled.h3`
    color: ${(props) => (props.color ? `${props.color}` : '#E8B304')};
    font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : 'normal')};
    text-align: center;
`;

export const SubTitle = styled.h5`
    font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : 'normal')};
    width: 80%;
    text-align: center;
    color: ${(props) => (props.color ? `${props.color}` : '#E8B304')};
    margin-top: -5px;
`;

export const SubText = styled.span`
    font-size: 1.2rem;
    text-align: center;
`;

export const ButtonTittle = styled.div`

    margin-top: ${(props) => (props.top ? `${props.top}px` : '0px')};

    a {
        color: #FFF;
        font-weight: bold;
        font-size: 1.2rem;
        border: 1px solid #E8B304;
        padding: 15px;

        &:hover {
            background-color: #E8B304;
            transition: ease-in-out .4s;
            cursor: pointer;
        }

    }
`