import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import { carouselSettings } from '../../configs'
import Api from "../../../../api/api";

const StackCarousel = () => {

    const [items, setItems] = useState([]);

    const handleItems = async () => {
        const response = await Api.get('site/stack');
        setItems(response);
    }

    useEffect(() => {
        handleItems()
    }, []);

    return (
        <Slider autoplay={true} infinite={true} slidesToShow={6} {...carouselSettings}>
            {items && items.map((item, index) => (
                <div key={index} className="row">
                    <div className="stack-card">
                        <div className="stack-img">
                            <img src={`https://z1tec.com.br/cdn/images/site/stacks/${item.image}`} alt={`${item.title} Logo`} />
                        </div>
                        <div className="stack-description">
                            <small>{item.title}</small>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );
}

export default StackCarousel;
