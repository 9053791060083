import React from "react";
import { Link } from 'react-scroll';

const SideNav = () => {

    return (
        <ul className="sidenav" id="mobile-nav">
            <li>
                <Link className="nav-item" to="header" smooth={true} offset={0}>
                    <i className="material-icons">home</i>
                    Home
                </Link>
            </li>
            <li>
                <Link className="nav-item" to="services" smooth={true} offset={-80}>
                    <i className="material-icons">design_services</i>
                    Serviços
                </Link>
            </li>
            <li>
                <Link className="nav-item" to="contact" smooth={true} offset={-80}>
                    <i className="material-icons">perm_phone_msg</i>
                    Contato
                </Link>
            </li>
            <li><a href="https://admin.z1tec.com.br" className="nav-item"><i className="material-icons">lock</i> Area Restrita</a></li>
        </ul>
    )

}

export default SideNav;