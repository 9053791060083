import React from "react";
import PartnersCarousel from "./carousel";
import { Content, SubTitle, Title } from "../../../../components/content";

const Partners = () => {

    return (
        <section id="#partners">
            <div className="container">
                <Content height={200}>
                    <Title color={"#000"}>Nossos parceiros</Title>
                    <SubTitle color="#000">Conheça alguns dos clientes que confiam em nossa solução!</SubTitle>
                </Content>

            </div>
            <div className="container partners-carousel">
                <PartnersCarousel />
            </div>
        </section>
    )
}

export default Partners;