import React, { useState, useEffect } from "react";
import Api from "../../../../api/api";
import Slider from 'react-slick';
import { carouselSettings } from '../../configs'

const AdditionalsCarousel = () => {

    const [items, setItems] = useState([]);

    const handleCarousel = async () => {
        let data = await Api.get('site/deskflow');
        setItems(data.filter((item) => item.additional === 1));
    }

    useEffect(() => {
        handleCarousel();
    }, []);

    return (
        <Slider autoplay={true} infinite={true} slidesToShow={3} {...carouselSettings}>
            {items.map((item, index) => (
                <div key={index} className="row">
                    <div className="module-card">
                        <div className="module-icon">
                            <i className="material-icons">{item.icon}</i>
                        </div>
                        <div className="module-title">
                            {item.title}
                        </div>
                        <div className="module-description">
                            <ul dangerouslySetInnerHTML={{
                                __html: item.description
                                    .split(';')
                                    .map((desc) => `<li>${desc.trim()}</li>`)
                                    .join('\n'),
                            }} />
                        </div>
                    </div>
                </div >
            ))
            }
        </Slider >
    )

}

export default AdditionalsCarousel;