import React, { useEffect, useState } from 'react';
import Api from '../../../../api/api';
import Slider from 'react-slick';
import { carouselSettings } from '../../configs'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const ServicesCarousel = () => {

    const [items, setItems] = useState([]);

    const handleCarousel = async () => {
        let items = await Api.get('site/services');
        setItems(items);
    }

    useEffect(() => {
        handleCarousel();
    }, []);

    return (
        <Slider slidesToShow={4} {...carouselSettings}>
            {items.map((item, index) => (
                <div key={index} className="row">
                    <div className="item">
                        <div className="col s12 m12">
                            <div className="card">
                                <div className="card-icon">
                                    <i className="material-icons">{item.icon}</i>
                                </div>
                                <div className="card-content black-text service-content">
                                    <span className="card-title center-align">{item.title}</span>
                                    <p className="center-align" dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default ServicesCarousel;