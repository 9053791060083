import React, { useEffect, useState } from "react";
import Banner from './sections/banner';
import Services from './sections/services';
import Hero from './sections/hero';
import Parallax from './sections/parallax';
import Partners from './sections/partners';
import Map from './sections/map/map';
import Contact from './sections/contact';
import Cookie from "../../components/cookie";

const Main = () => {

    const [cookie, setCookie] = useState(false);

    const handleCookie = async () => {

        setCookie(false);
        const isAgreed = await localStorage.getItem('cookie');

        if (!isAgreed || isAgreed === null) {
            await localStorage.setItem('cookie', false);
        }

        setCookie(isAgreed === "false" ? false : true);
    }

    useEffect(() => {
        document.title = 'Z1Tec - Gestão em Tecnologia';
        handleCookie();
    }, []);

    return (
        <div>
            {!cookie && <Cookie />}
            <Banner />
            <Services />
            <Hero />
            <Parallax />
            <Partners />
            <Map />
            <Contact />
        </div>
    )

}

export default Main;