import React, { useEffect, useState } from "react";
import { activateSquares, typeWriter } from "./effects";
import { Link } from 'react-scroll';
import Loader from '../../../../components/loader';
import Api from '../../../../api/api';
import {
    ButtonTittle,
    Content,
    SubTitle,
    Title
} from "../../../../components/content"

const Banner = () => {

    const [header, setHeader] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleHeader = async () => {

        setLoading(true);
        const item = await Api.get('site/header');
        typeWriter(document.querySelector(".typewritter-text"));

        if (item) {
            setHeader(item);
        }

        setLoading(false);
    }

    useEffect(() => {
        handleHeader();
        activateSquares();
    }, []);

    return (
        <section className="banner">
            <div className="container">
                <div className="row">
                    {loading ? (<Loader />) : (
                        <Content>
                            <Title className="typewritter-text">Desenvolvimento moderno, inteligente e flexível.</Title>
                            <SubTitle>
                                {header.description}
                            </SubTitle>
                            <ButtonTittle style={{ marginTop: '50px' }}>
                                <Link className="btn-header" to="services" smooth={true} offset={-80}>
                                    Conheça nossos serviços
                                </Link>
                            </ButtonTittle>
                        </Content>
                    )}
                </div>
                <ul className="squares"></ul>
            </div>
        </section>
    );
};

export default Banner;