export function  activateSquares() {

    const ulSquares = document.querySelector("ul.squares");

    if (ulSquares !== null) {

        for (let i = 0; i < 11; i++) {

            const li = document.createElement("li");

            const random = (min, max) => Math.random() * (max - min) + min;
            const size = Math.floor(random(10, 120));
            const position = random(1, 50);
            const delay = random(5, 0.1)
            const duration = random(24, 12);


            li.style.width = `${size}px`;
            li.style.height = `${size}px`;
            li.style.bottom = `-${size}px`;
            li.style.left = `${position}%`;
            li.style.animationDelay = `${delay}s`;
            li.style.animationDuration = `${duration}s`;
            li.style.animationTimingFunction = `cubic-bezier(${Math.random}, ${Math.random}, ${Math.random}, ${Math.random})`
            ulSquares.appendChild(li);

        }
    }

}

export function typeWriter(el, text = null) {

    if (el != null) {
        let textArray = text == null ? el.innerHTML.split('') : text.split('');
        el.innerHTML = '';
        textArray.forEach((letter, i) => {
            setTimeout(() => el.innerHTML += letter, 75 * i);
        });
    }
}