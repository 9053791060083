import React, { useState } from "react";

const Cookie = () => {

    const [isVisible, setIsVisible] = useState(true);

    const handleButtonClick = async (status) => {
        await localStorage.setItem('cookie', status);
        setIsVisible(false);
    };


    return (
        <div className={`container-fluid ${isVisible ? 'fadeIn' : 'fadeOut'}`}>
            <div className="row">
                <div className="cookie-container">
                    <div className="container">
                        <div className="cookie-content">
                            <div className="cookie-text">
                                <span>Utilizamos cookies em nosso site para ver como você interage com ele. Ao aceitar, você concorda com o uso de cookies.</span>
                            </div>
                            <div className="cookie-buttons">
                                <button onClick={() => { handleButtonClick(false) }}>Recusar</button>
                                <button onClick={() => { handleButtonClick(true) }}>Aceitar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default Cookie;