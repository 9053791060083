import React, { useEffect, useState } from 'react';
import Api from '../../../../api/api';
import Slider from 'react-slick';
import { carouselSettings } from '../../configs'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const PartnersCarousel = () => {

    const [partners, setPartners] = useState([]);

    const handleCarousel = async () => {
        const data = await Api.get('site/partners');
        setPartners(data);
    }

    useEffect(() => {
        handleCarousel();
    }, []);

    return (
        <Slider slidesToShow={partners.length > 4 ? 4 : partners.length} {...carouselSettings}>
            {partners && partners.map((item, index) => (
                <div key={index} className="row">
                    <div className="col s12 m12">
                        <div className="partner-card">
                            <div className="card-image">
                                <a href={item.link} target="_blank" rel="noreferrer">
                                    <img src={`https://z1tec.com.br/cdn/images/site/${item.logo}`} alt={`Logo do Parceiro ${item.name}`} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default PartnersCarousel;