import React from "react";

const Loader = () => {
    return (
        <div className="loader-wrapper">
            <div className="preloader-wrapper big active">
                <div id="preload" className="spinner-layer">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>
            </div>
            <span>Carregando...</span>
        </div>
    );
}


export default Loader;