import React, { useEffect } from "react";
import Landing from "../deskflow/sections/landing";

const Deskflow = () => {

    useEffect(() => {
        document.title = 'Z1Tec - DeskFlow™';
    },[]);

    return (
        <Landing />
    )

}

export default Deskflow;