import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/home/logo-s.png";
import { Link } from 'react-scroll';
import M from 'materialize-css/dist/js/materialize.min.js';
import SideNav from "../../components/sidenav";

const Header = () => {

  const [scrolling, setScrolling] = useState(false);

  const handleMouseEnter = () => {

    const nav = document.querySelector(".header-nav");

    if (nav.classList.contains('transparent-header')) {
      nav.classList.remove('transparent-header');
    }

  }

  const handleMouseLeave = () => {

    const nav = document.querySelector(".header-nav");

    if (!nav.classList.contains('transparent-header')) {
      nav.classList.add('transparent-header');
    }

  }

  useEffect(() => {

    const handleScroll = () => {

      const isTop = window.scrollY > 780;

      if (isTop !== scrolling) {
        setScrolling(isTop);
      }

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolling]);


  useEffect(() => {
    var sidenav = document.querySelectorAll('.sidenav');
    M.Sidenav.init(sidenav);
  }, [])



  return (
    <header id="header">
      <menu>
        <nav className={scrolling ? 'header-nav transparent-header' : 'header-nav'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className="nav-wrapper">
            <button href="#" id="sidenav-trigger" data-target="mobile-nav" className="sidenav-trigger">
              <i className="material-icons">menu</i>
            </button>
            <div className="container header-container">
              <div className="logo">
                <Link className="brand-logo" to="header" smooth={true}>
                  <img src={Logo} alt="Logo da Z1" />
                </Link>
              </div>
              <div className="menu">
                <ul id="main-menu" className="right hide-on-med-and-down menu">
                  <li>
                    <Link className="nav-item" to="header" smooth={true} offset={0}>Home</Link>
                  </li>
                  <li>
                    <Link className="nav-item" to="services" smooth={true} offset={-80}>Serviços</Link>
                  </li>
                  <li>
                    <Link className="nav-item" to="contact" smooth={true} offset={0}>Contato</Link>
                  </li>
                  <li><a className="nav-item" href="https://admin.z1tec.com.br">
                    <i className="material-icons">lock</i>
                  </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </menu>
      <SideNav />
    </header>
  );
}

export default Header;