import React from "react";
import ServicesCarousel from "./carousel";

const Services = () => {

    return (
        <main id="#services">
            <div className="container">
                <div className="services" >
                    <div className="services-title">
                        <h1>Nossos Serviços</h1>
                    </div>
                    <div className="services-text">
                        <span>Conheça algumas das soluções que oferecemos para agilizar o seu negócio!</span>
                    </div>
                </div>
            </div>

            <div className="container">
                <ServicesCarousel />
            </div>
        </main>
    );
}

export default Services;