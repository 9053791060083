import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
    
        return (
            <footer className="footer">
                <div className="footer-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m3">
                                <h4 className="footer-tittle">Contato</h4>
                                <ul className="footer-list">
                                    <li>R. Nicolau Latchoc, 55, Campo Comprido, Curitiba - PR</li>
                                    <li><a href="mailto:contato@z1tec.com.br">contato@z1tec.com.br</a></li>
                                </ul>
                            </div>

                            <div className="col s12 m3">
                                <h4 className="footer-tittle">Midias Sociais</h4>
                                <ul className="footer-list footer-list-inline">
                                    <li>
                                        <a href="http://www.instagram.com/z1_tec" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faInstagram}  className="instagram-icon"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/z1tec" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFacebookF} className="facebook-icon"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div >
                </div >
            </footer >
        )
    }

export default Footer;