import React, { useState, useRef } from "react";
import { Content, SubTitle, Title } from "../../../../components/content";
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import { IMaskInput } from "react-imask";
import * as Yup from 'yup';
import { initialFormData, toasterSettings } from "./form";
import PreLoader from "../../../../components/preloader";
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../../../api/api';


const Contact = () => {

    const [captchaValue, setCaptchaValue] = useState('');
    const [data, setData] = useState(initialFormData);
    const [loading, setLoading] = useState(false);
    const recaptchaRef = useRef();
    const recaptchaKey = '6LfZT50gAAAAAC1MACF6JhLX1qJGv4ZAKC1yPf97';

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Preencha o campo nome.').min(3, 'O campo email precisa conter pelo menos 3 caracteres.'),
        email: Yup.string().email('Digite um e-mail válido').required('Preencha o campo email.'),
        phone: Yup.string().required('Preencha o campo telefone.'),
        subject: Yup.string().required('Preencha o campo assunto.')
    });

    const handleSubmit = async (event) => {

        event.preventDefault();

        await validationSchema.validate(data, { abortEarly: false }).then(async () => {

            if (!captchaValue) {
                return toast.error("Por favor, preencha o ReCAPTCHA.", toasterSettings);
            }

            setLoading(true)

            const response = await toast.promise(Api.post('site/contact', data), {
                pending: `Estamos processando sua solicitação, por favor aguarde um momento.`,
                success: `Seu chamado foi registrado e será encaminhado para um analista.`,
                error: 'Não foi possível processar o chamado.',
            }, toasterSettings);

            if(response.status === "ok") {
                setLoading(false);
                clearFields();
            }


        }).catch((validationError) => {
            for (const error of validationError.inner) {
                toast.error(error.message, toasterSettings);
                break;
            }
        });
    }

    const clearFields = () => {
        setData(initialFormData);
        recaptchaRef.current.reset();
        setLoading(false);
    }

    return (
        <section id="contact">
            <div className="container">
                <Content>
                    <Title color={"#000"}>Entre em contato!</Title>
                    <SubTitle color={"#000"}>Preencha o formulário abaixo, e deixe o resto com a gente! :)</SubTitle>
                </Content>
                <form id="form-contact" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="input-field col m12 s12">
                            <input
                                name="name"
                                placeholder="Nome Completo"
                                type="text"
                                className="validate"
                                value={data.name}
                                onChange={handleInputChange}
                                data-name="Nome"
                            />
                            <label className="frm-label active" htmlFor="name"><small>*</small>Nome</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col m6 s12">
                            <input
                                name="email"
                                placeholder="Email Válido"
                                type="text"
                                className="validate"
                                value={data.email}
                                onChange={handleInputChange}
                                data-name="Email"
                            />
                            <label className="frm-label active" htmlFor="email"><small>*</small>Email</label>
                        </div>
                        <div className="input-field col m6 s12">
                            <IMaskInput
                                name="phone"
                                placeholder="(00) 00000-0000"
                                value={data.phone}
                                data-name="Telefone"
                                mask="(00) 00000-0000"
                                onChange={handleInputChange}
                            />
                            <label className="frm-label active" htmlFor="phone"><small>*</small>Telefone</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <textarea
                                placeholder="Descreva o motivo do contato."
                                name="subject"
                                className="materialize-textarea"
                                value={data.subject}
                                onChange={handleInputChange}
                                data-name="Assunto"
                            />
                            <label className="frm-label active" htmlFor="subject"><small>*</small>Assunto</label>
                        </div>
                    </div>
                    <div className="row recaptcha-container">
                        <Content>
                            <ReCAPTCHA sitekey={recaptchaKey} onChange={handleCaptchaChange} ref={recaptchaRef} />
                        </Content>
                    </div>
                    <div className="row">
                        <Content>
                            <button className="btn-message" type="submit">
                                {loading ? (<PreLoader />) : ('Enviar')}
                            </button>
                        </Content>
                    </div>
                    <ToastContainer />
                </form>
            </div>
        </section>
    )
}

export default Contact;